@use '../../../../abstracts/variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/mixins/Depth.Mixins';

.board-legend {
  display: flex;
  margin: 5px 10px 0;

  li {
    margin: 0 5px;

    .board-legend-icon {
      background: variables.$board-variant-grey-blue;
      border-radius: 50%;
      display: inline-block;
      height: 10px;
      width: 10px;

      &.is-grey-blue {
        background: variables.$board-variant-grey-blue;
      }

      &.is-purple {
        background: variables.$board-variant-purple;
      }

      &.is-orange {
        background: variables.$board-variant-orange;
      }

      &.is-yellow {
        background: variables.$board-variant-yellow;
      }

      &.is-grey {
        background: variables.$board-variant-grey;
      }

      &.is-green {
        background: variables.$board-variant-green;
      }

      &.is-red {
        background: variables.$board-variant-red;
      }
    }
  }
}
