.loader {
  text-align: center;

  > div {
    display: inline-block;
    height: 80px;
    position: relative;
    text-align: center;
    width: 80px;

    div {
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      border: 6px solid #fff;
      border-radius: 50%;
      bottom: 0;
      height: 100px;
      left: 0;
      margin: 0 auto;
      opacity: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 100px;

      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }
}

@keyframes lds-ripple {
  0% {
    opacity: 100%;
    transform: scale3d(0.01, 0.01, 1);
  }

  100% {
    opacity: 0%;
    transform: scale3d(1, 1, 1);
  }
}

// https://loading.io/css
