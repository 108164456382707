@use '../../../../abstracts/variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/mixins/Depth.Mixins';

$card-bg: #f4f3f1;
$button-bg: #dadada;
$light-grey-font: #a8a8a7;

.board-card {
  background: $card-bg;
  border-radius: 1%;
  box-shadow: $ms-depth-shadow-4;
  display: flex;
  flex-direction: column;
  // height: 125px;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 5px 10px;
  width: 100%;

  &.is-clickable {
    cursor: pointer !important;

    .board-card-buttons-button {
      pointer-events: none;
    }
  }

  &[draggable='true'] {
    cursor: grab;

    &:active * {
      cursor: grabbing;
    }
  }

  .board-card-buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: -8px;
    margin-top: 15px;

    .board-card-buttons-button {
      background: $button-bg;
      /* stylelint-disable-next-line declaration-property-value-disallowed-list */
      border: none;
      border-radius: 5%;
      cursor: pointer;
      line-height: normal;
      margin: 0 2px;
      padding: 5px 8px 5px 5px;
      transition: background 0.1s;
      width: auto;

      &:disabled {
        cursor: not-allowed;
      }

      &:hover:not(:disabled) {
        background: darken($color: $button-bg, $amount: 5);
      }

      &.is-outlined {
        background: transparent;
        border: 1px solid darken($color: $button-bg, $amount: 15);

        &:hover {
          background: lighten($color: $button-bg, $amount: 50);
        }
      }
    }
  }

  &.is-grey-blue {
    border-left: 5px solid variables.$board-variant-grey-blue;

    .board-card-header .board-card-header-type span {
      color: variables.$board-variant-grey-blue;
    }

    .board-card-buttons .board-card-buttons-button span {
      color: variables.$board-variant-grey-blue;
    }
  }

  &.is-purple {
    border-left: 5px solid variables.$board-variant-purple;

    /* stylelint-disable-next-line no-descending-specificity */
    .board-card-header .board-card-header-type span {
      color: variables.$board-variant-purple;
    }

    .board-card-buttons .board-card-buttons-button span {
      color: variables.$board-variant-purple;
    }
  }

  &.is-yellow {
    background: lighten($color: variables.$board-variant-yellow, $amount: 50);
    border-left: 5px solid variables.$board-variant-yellow;

    /* stylelint-disable-next-line no-descending-specificity */
    .board-card-header .board-card-header-type span {
      color: darken($color: variables.$board-variant-yellow, $amount: 25);
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .board-card-buttons .board-card-buttons-button {
      background: lighten($color: variables.$board-variant-yellow, $amount: 40);

      &:hover {
        background: lighten($color: variables.$board-variant-yellow, $amount: 37);
      }

      span {
        color: darken($color: variables.$board-variant-yellow, $amount: 25);
      }
    }
  }

  &.is-orange {
    background: lighten($color: variables.$board-variant-orange, $amount: 50);
    border-left: 5px solid variables.$board-variant-orange;

    /* stylelint-disable-next-line no-descending-specificity */
    .board-card-header .board-card-header-type span {
      color: variables.$board-variant-orange;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .board-card-buttons .board-card-buttons-button {
      background: lighten($color: variables.$board-variant-orange, $amount: 40);

      &:hover {
        background: lighten($color: variables.$board-variant-orange, $amount: 37);
      }

      span {
        color: variables.$board-variant-orange;
      }
    }
  }

  &.is-grey {
    background: lighten($color: variables.$board-variant-grey, $amount: 50);
    border-left: 5px solid variables.$board-variant-grey;

    /* stylelint-disable-next-line no-descending-specificity */
    .board-card-header .board-card-header-type span {
      color: darken($color: variables.$board-variant-grey, $amount: 25);
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .board-card-buttons .board-card-buttons-button {
      background: lighten($color: variables.$board-variant-grey, $amount: 40);

      &:hover {
        background: lighten($color: variables.$board-variant-grey, $amount: 37);
      }

      span {
        color: darken($color: variables.$board-variant-grey, $amount: 25);
      }
    }
  }

  &.is-green {
    background: lighten($color: variables.$board-variant-green, $amount: 52);
    border-left: 5px solid variables.$board-variant-green;

    /* stylelint-disable-next-line no-descending-specificity */
    .board-card-header .board-card-header-type span {
      color: darken($color: variables.$board-variant-green, $amount: 25);
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .board-card-buttons .board-card-buttons-button {
      background: lighten($color: variables.$board-variant-green, $amount: 40);

      &:hover {
        background: lighten($color: variables.$board-variant-green, $amount: 37);
      }

      span {
        color: darken($color: variables.$board-variant-green, $amount: 25);
      }
    }
  }

  &.is-red {
    background: lighten($color: variables.$board-variant-red, $amount: 52);
    border-left: 5px solid variables.$board-variant-red;

    /* stylelint-disable-next-line no-descending-specificity */
    .board-card-header .board-card-header-type span {
      color: darken($color: variables.$board-variant-red, $amount: 25);
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .board-card-buttons .board-card-buttons-button {
      background: lighten($color: variables.$board-variant-red, $amount: 40);

      &:hover {
        background: lighten($color: variables.$board-variant-red, $amount: 37);
      }

      span {
        color: darken($color: variables.$board-variant-red, $amount: 25);
      }
    }
  }

  .board-card-header {
    display: flex;
    justify-content: space-between;

    .board-card-header-checked {
      color: #a0aeb2;
      font-size: 14px;

      &.is-active {
        color: variables.$board-variant-grey-blue;
      }

      &.is-disabled {
        color: lighten($color: variables.$dark-grey, $amount: 40);
      }
    }

    .board-card-header-type {
      display: inline-block;
      // margin-bottom: 0;
      // margin-bottom: -3px;
      // text-align: right;
      // width: 100%;

      i {
        vertical-align: text-top;
      }
    }
  }

  .board-card-candidate {
    font-weight: 600;
    margin-bottom: -3px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
  }

  .board-card-subtitle {
    margin-bottom: -3px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
  }
}
