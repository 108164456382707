.missions-map-legend {
  margin-top: 10px;

  li {
    display: inline-block;
    margin-right: 10px;

    img {
      display: inline-block;
      margin-bottom: -2px;
      width: 16px;
    }
  }
}
