@use '../../../abstracts/variables';
@use '../../../abstracts/mixins';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/mixins/Depth.Mixins';

$light-blue: #00b0e3;
$light-grey: #d2d2d2;
$grey-blue: #5795a1;

.pivot-candidacy {
  scrollbar-width: thin;

  @include mixins.from(mixins.$desktop) {
    margin-left: calc(50% - 50vw + (#{variables.$menu-close-width} * 2.25));
    width: calc(100vw - (#{variables.$menu-close-width} * 2 * 2.25));
  }

  ::-webkit-scrollbar {
    height: 10px;
    width: 9px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba($grey-blue, 50%);
    border: transparent;
    border-radius: 5px;
  }
}

section[class*='is-menu-close'] {
  .pivot-candidacy {
    @include mixins.from(mixins.$desktop) {
      margin-left: calc(50% - 50vw + #{variables.$menu-close-width});
      width: calc(100vw - (#{variables.$menu-close-width} * 2));
    }
  }
}
