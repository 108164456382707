@use '../../../abstracts/variables';

.home-card-title {
  display: inline-flex;
  justify-content: space-between;
  width: calc(100% - 23px);

  span.home-card-title-right {
    align-self: flex-end;

    span.home-card-title-right-text {
      font-size: 14px;
    }
  }

  &.is-grey-blue {
    span.home-card-title-right {
      color: variables.$board-variant-grey-blue;
    }
  }

  &.is-purple {
    span.home-card-title-right {
      color: variables.$board-variant-purple;
    }
  }

  &.is-light-blue {
    span.home-card-title-right {
      color: variables.$other-variant-grey-blue;
    }
  }
}
