@import '~bulma/sass/utilities/initial-variables.sass';
@import '~bulma/sass/utilities/derived-variables.sass';

/***************************
********* Colors 🎨 ********
***************************/
$primary: #15535e;
$dark-primary: #083e47;
$darker-primary: #002930;

$white: #fff;

$grey: #f3f2f1; //Example: left menu
$light-grey: #fbfaf9; //Example: base background
$dark-grey: #605e5c;

$black: #323130; //Default: Font color

//Used to add colors as bulma helpers
$colors: map-merge($colors, ( 'dark-primary': ( $primary, $dark-primary, ),));

$board-variant-grey-blue: #5795a1;
$board-variant-blue: #0563a1;
$board-variant-purple: #8735d9;
$board-variant-orange: #e35506;
$board-variant-yellow: #fce100;
$board-variant-green: #24a072;
$board-variant-red: #c20a0a;
$board-variant-grey: $dark-grey;
$board-card-bg: #f4f3f1;
$board-button-bg: #dadada;
$board-date-font: #a8a8a7;

$other-variant-grey-blue: #00b0e3;

/***************************
******** Settings ⚙ *******
***************************/
$menu-width: 230px;
$menu-close-width: 4em;
$nav-bar-height: 52px;
$top-bar-height: 44px;
