@use '../../../../abstracts/variables';
@use './boardCard.module.scss' as card;
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/mixins/Depth.Mixins';

.board-to-call-card {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .board-card;

  align-items: center;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 40px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  i {
    color: card.$light-grey-font;
  }

  span {
    color: card.$light-grey-font;
    margin-left: 5px;
  }
}
