@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.divider {
  background-color: variables.$grey;
  border-bottom: 1px solid variables.$grey;
  height: 1px;
  margin: 0.5em 0 1.5em;

  span {
    background-color: variables.$primary;
    display: block;
    height: 1px;
    width: 50px;
  }
}
