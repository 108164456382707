/* stylelint-disable no-invalid-position-at-import-rule */
@use './variables';

@import '~bulma/sass/utilities/initial-variables.sass';
@import '~bulma/sass/utilities/functions.sass';
@import '~bulma/sass/utilities/derived-variables.sass';
@import '~bulma/sass/utilities/mixins.sass';

@mixin btn-red {
  &:not([disabled]) {
    background-color: #f14668;
    border: 1px solid #f14668;
    color: variables.$white;

    &:hover {
      /* stylelint-disable-next-line function-no-unknown */
      background-color: darken($color: #f14668, $amount: 5);
    }
  }
}
