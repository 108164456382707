@use '../../../../abstracts/variables';
@use '../../../../abstracts/mixins';

.board-detail-panel {
  .board-detail-panel-add-comment-button {
    display: block;
    margin-left: auto;
    margin-right: 0;
  }

  .board-detail-panel-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5px;
    justify-content: flex-start;
  }

  button.is-red {
    @include mixins.btn-red;
  }
}
