/* stylelint-disable no-descending-specificity */
@use '../abstracts/mixins';
@use '../abstracts/variables';
@use '../bases/helpers';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/mixins/Depth.Mixins';

.layout {
  //height: 100vh;
  //overflow: auto;

  nav[class*='navbar'] {
    @include ms-depth-16;

    background: linear-gradient(to left, variables.$primary, variables.$darker-primary);

    [class*='navbar-brand'] {
      span {
        color: variables.$white;
      }

      [class*='navbar-item'] {
        span {
          color: variables.$white;
          margin-left: 13px;
        }
      }
    }

    [class*='navbar-end'] {
      i {
        color: variables.$white !important;
      }

      [class*='navbar-item'] {
        &.is-tab:hover {
          border-bottom-color: #fff !important;
        }

        &:hover {
          border-bottom-color: #fff !important;
          color: #fff !important;

          i {
            color: #fff !important;
          }
        }
      }
    }

    a:hover {
      i {
        border-bottom-color: variables.$primary !important;
        color: variables.$primary !important;
      }
    }

    [class*='navbar-menu'] {
      @include mixins.until(variables.$desktop) {
        left: 0;
        position: absolute;
        right: 0;
      }

      [class*='navbar-start'] {
        i {
          color: variables.$primary !important;
          vertical-align: bottom;
        }

        [class*='navbar-link'] {
          &:hover {
            background: unset !important;
            color: unset !important;
            cursor: auto;
          }
        }

        a:hover {
          background: variables.$light-grey;
          color: variables.$primary !important;
        }
      }
    }
  }

  div.content-layout {
    background-color: variables.$light-grey;
    // background-color: $dark-primary;
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23163e7e' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%231c4987' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23225590' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%2327609a' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%232b6ca3' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%235f87b5' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%2389a4c7' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23b0c1da' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23d8e0ec' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23ffffff' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
    // background-attachment: fixed;
    // background-size: cover;

    /* background by SVGBackgrounds.com */
    display: flex;
    min-height: 100%;
    min-height: calc(100vh - #{variables.$nav-bar-height});
    overflow: hidden;

    div.menu-layout {
      background-color: rgba(variables.$grey, 0.9);
      min-width: variables.$menu-width;

      &.is-close {
        //When menu is closed
        min-width: 65px;
        // min-width: 0;
        overflow: hidden;

        i {
          margin-left: -3px;
        }

        i + span,
        p[class*='menu-label'] {
          display: none;
        }

        ul[class*='menu-list'] {
          ul[class*='menu-list'] {
            display: none;
          }

          li a {
            &:hover {
              background: rgba(variables.$light-grey, 0.9);
            }

            &[class*='is-active'] {
              background: rgba(variables.$primary, 0.9);
            }
          }
        }

        + div.main-layout div.body-layout div.body-content-layout {
          width: calc(100vw - #{variables.$menu-close-width});
        }
      }

      aside {
        margin-top: 0;
        padding: 1em;
        padding-top: 0;

        div[class*='navbar-burger'] {
          display: block;
          height: 44px;
          margin-left: -0.5em !important;
          outline: currentColor none medium;
          width: 44px;
        }

        hr {
          color: variables.$light-grey;
          height: 1px;
          margin: 0 -1em 35px;
        }

        p[class*='menu-label'] {
          margin-top: 3em;
        }

        ul[class*='menu-list'] {
          li {
            a {
              margin-bottom: 0.5em;

              &:hover {
                background: linear-gradient(to right, rgba(variables.$white, 0.9) 55%, rgba(variables.$white, 0));
              }

              &[class*='is-active'] {
                background: linear-gradient(to right, rgba(variables.$dark-primary, 0.9), rgba(variables.$primary, 0.9) 55%, rgba(variables.$white, 0));

                span {
                  color: variables.$white;
                }
              }

              i {
                vertical-align: bottom;

                + span {
                  margin-left: 0.2em;
                }
              }
            }
          }

          ul {
            border-left: 1px solid rgba(variables.$dark-primary, 0.5);
          }
        }
      }
    }

    div.main-layout {
      width: 100%;

      div[class*='ms-CommandBar'] {
        background-color: rgba(variables.$grey, 0.9);

        [class*='ms-OverflowSet-overflowButton'] {
          button {
            background: transparent;

            i {
              color: variables.$dark-primary;
            }
          }
        }

        [class*='ms-OverflowSet-item'] {
          @include helpers.flex-col();

          > [class*='ms-TooltipHost'] {
            @include helpers.flex-col();

            height: 100%;
          }

          > * {
            height: 75%;
          }

          button[class*='ms-Button'] {
            background-color: transparent;
            border-radius: 1px;

            &:not([class*='is-disabled']) {
              color: variables.$primary;

              &:hover {
                background: variables.$primary;

                span {
                  color: variables.$white !important;
                }

                i {
                  color: variables.$white;
                }
              }
            }

            &[class*='is-expanded'][class*='ms-Button--hasMenu'] {
              &:hover {
                i[class*='ms-Icon'][class*='ms-Button-menuIcon'][class*='is-expanded'] {
                  background-color: unset;
                }
              }
            }

            [class*='ms-Button-label'] {
              font-weight: bold;
              overflow: visible !important;
            }

            i {
              font-weight: bold;
            }
          }
        }
      }

      div.body-layout {
        box-shadow: inset $ms-depth-shadow-8;
        height: calc(100vh - variables.$top-bar-height - variables.$nav-bar-height);
        // min-height: calc(100vh - ((#{variables.$top-bar-height} * 2) + #{variables.$nav-bar-height}));
        overflow: auto;

        div.body-content-layout {
          margin: 0 auto;
          max-width: 1450px;
          padding: 10px 35px;
          width: calc(100vw - #{variables.$menu-width});

          @include mixins.until(variables.$tablet) {
            padding: 5px 10px;
          }

          @include mixins.until(variables.$desktop) {
            width: 100% !important;
          }

          > [class*='ms-MessageBar'] {
            @include ms-depth-16;
          }

          .body-content-layout-title {
            color: variables.$darker-primary;
            display: block;
            font-weight: 600;
            margin-top: 6px;
            padding-left: 6px;
          }

          [class*='ms-Breadcrumb'][role*='navigation'] {
            margin: 0 0 13px;

            i {
              font-size: 12px;
              margin: 0 auto -2px;
            }
          }

          [class*='ms-Breadcrumb']:not([class*='ms-Breadcrumb-listItem']):not([class*='ms-Breadcrumb-item']):not([class*='ms-Breadcrumb-chevron']) {
            ol {
              margin: 0;
            }

            li {
              span {
                color: variables.$primary;
                cursor: auto;
              }

              a > * {
                cursor: pointer;
              }

              + li {
                margin-top: 0 !important;
              }

              &:not(:last-child) {
                // span,
                // button {
                //   color: $white;
                // }
                button:hover {
                  background: initial;
                }
              }

              &:last-child {
                span,
                button {
                  color: variables.$darker-primary;
                  text-shadow: 0 0 2px rgba(255, 255, 255, 40%);
                }
              }

              span,
              button {
                font-size: 16px;
              }
            }
            // .ms-Breadcrumb-chevron {
            //   color: $white;
            // }
            [class*='ms-Breadcrumb-overflow'] {
              > button {
                &:active,
                &:hover,
                &.is-expanded {
                  background: rgba(234, 234, 234, 20%) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
