@use '../../../abstracts/variables';
@use '../../../abstracts/mixins';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/mixins/Depth.Mixins';

.home-top {
  display: flex;
  justify-content: space-between;
  margin: -40px 0 15px;

  @include mixins.until(mixins.$widescreen) {
    flex-direction: column;
    gap: 1.5rem;
    margin: -1rem 0 1.5rem;
  }

  .home-top-left {
    flex: 1;
  }

  .home-top-center {
    align-self: center;

    .home-top-center-text {
      background: lighten($color: variables.$primary, $amount: 72);
      border-radius: 5px;
      box-shadow: $ms-depth-shadow-8;
      color: lighten($color: variables.$primary, $amount: 5);
      display: block;
      flex: 1;
      padding: 0.5rem;
      width: max-content;

      b {
        font-weight: bold;
      }
    }
  }

  .home-top-right {
    align-self: center;
    display: flex;
    flex: 1;
    gap: 1rem;
    justify-content: flex-end;

    .home-top-right-container {
      border-left: 2px solid variables.$primary;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 0.5rem;

      p {
        display: flex;
        justify-content: space-between;

        span {
          &:first-child {
            color: lighten($color: variables.$primary, $amount: 10);
            font-weight: bold;
            padding-right: 0.5rem;
          }
        }
      }
    }
  }
}
