@use '../../../abstracts/variables';
@use '../../../abstracts/mixins';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/mixins/Depth.Mixins';

.pivot-diffusion {
  @include ms-depth-16;

  background: variables.$white;
  border-radius: 2px;
  box-shadow: $ms-depth-shadow-16;
  overflow: hidden;
  padding: 20px;
  word-break: break-word;

  header {
    display: flex;
    flex-direction: row;

    .header-title-refresh {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;

      .refresh-spinner {
        transform: translate(-8px);
      }
    }

    .header-action-buttons {
      display: flex;
      flex: 1;
      flex-direction: row-reverse;

      button {
        margin-right: 1em;
      }
    }
  }

  .job-boards-container {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;

    li {
      max-width: 33%;
      min-width: 33%;
      padding: 1em;

      @media (max-width: 1000px) {
        min-width: 100%;
      }

      .job-board-card {
        background: variables.$white;
        border-radius: 2px;
        box-shadow: $ms-depth-shadow-16;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 20px;
        word-break: break-word;

        header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .job-board-reason {
          display: flex;
          flex-direction: row-reverse;
        }
      }
    }
  }
}
