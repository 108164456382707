@use '../../../abstracts/variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/mixins/Depth.Mixins';

$card-bg: #f4f3f1;

.home-candidacy-card {
  background: $card-bg;
  border-radius: 1%;
  box-shadow: $ms-depth-shadow-4;
  display: flex;
  flex-direction: column;
  // height: 110px;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 5px 10px;
  width: 100%;

  .home-candidacy-card-header {
    display: flex;
    justify-content: space-between;

    .home-candidacy-card-header-title {
      color: variables.$primary;
      font-weight: bold;
    }

    .home-candidacy-card-header-type {
      display: inline-block;

      i {
        vertical-align: text-top;
      }
    }
  }

  .home-candidacy-card-footer {
    span {
      color: lighten($color: variables.$primary, $amount: 15);
    }
  }

  &.is-grey-blue {
    .home-candidacy-card-header .home-candidacy-card-header-type span {
      color: variables.$board-variant-grey-blue;
    }
  }

  &.is-purple {
    .home-candidacy-card-header .home-candidacy-card-header-type span {
      color: variables.$board-variant-purple;
    }
  }

  &.is-red {
    background: lighten($color: variables.$board-variant-red, $amount: 52);
  }

  &.is-blue {
    background: lighten($color: variables.$board-variant-blue, $amount: 52);
  }

  // &.is-yellow {
  //   .home-candidacy-card-header .home-candidacy-card-header-type span {
  //     color: darken($color: variables.$board-variant-yellow, $amount: 5);
  //   }
  // }
}
