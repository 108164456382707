@use '../abstracts/variables';

.error-boundary {
  background: variables.$light-grey;
  display: grid;
  gap: 0 0;
  grid-template: 1fr auto 1fr / 1fr minmax(auto, 950px) 1fr;
  grid-template-areas:
    'top top top'
    'left main right'
    'bottom bottom bottom';
  min-height: 100vh;

  .top-area {
    grid-area: top;
  }

  .bottom-area {
    grid-area: bottom;
  }

  .left-area {
    grid-area: left;
  }

  .right-area {
    grid-area: right;
  }

  .main-area {
    grid-area: main;

    p {
      word-break: break-word;

      &.stack {
        font-size: 0.75rem;
        white-space: pre-wrap;
      }
    }
  }
}
