@use '../../abstracts/mixins';
@use '../../abstracts/variables';

.index {
  .index-search-filters {
    padding: 15px 8px;

    div[class*='columns'] {
      @include mixins.from(variables.$tablet) {
        margin-top: -30px;
      }

      @include mixins.until(variables.$tablet) {
        &:first-child {
          margin-top: -30px;
        }
      }
    }
  }
}
