@use '../../abstracts/variables';
@use '../../abstracts/mixins';

.index {
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(variables.$board-variant-grey-blue, 50%);
    border: transparent;
    border-radius: 5px;
  }

  .index-left-container {
    display: flex;
    flex-direction: column;
    gap: calc(2 * 0.75rem);
    height: calc(100vh - 185px);
    justify-content: space-between;

    .index-left-container-card {
      flex: 1;
      scrollbar-width: thin;

      .index-left-container-card-container {
        height: calc(100% - (27px + 1px + 0.5em + 1.5em));
        overflow-y: auto;
        scrollbar-width: thin;

        .is-grey-blue {
          color: variables.$board-variant-grey-blue;
        }

        .is-purple {
          color: variables.$board-variant-purple;
        }

        .is-grey {
          color: variables.$dark-grey;
        }

        .is-light-blue {
          color: variables.$other-variant-grey-blue;
        }
      }

      &.is-two-thirds {
        flex: 2;
      }
    }
  }

  .index-right-container {
    display: flex;
    flex-direction: column;
    gap: calc(2 * 0.75rem);
    height: calc(100vh - 185px);
    justify-content: space-between;

    @include mixins.until(mixins.$tablet) {
      height: auto;
    }

    .index-right-container-roadmap {
      flex: 1;

      @include mixins.until(mixins.$tablet) {
        min-height: 250px;
      }

      .index-right-container-roadmap-container {
        height: calc(100% - 60px);
        overflow-y: auto;
      }
    }

    .index-right-container-candidates {
      display: flex;
      flex: 2;
      gap: 0.5rem;
      height: 0;
      justify-content: center;
      scrollbar-width: thin;

      @include mixins.until(mixins.$tablet) {
        flex-direction: column;
        gap: calc(2 * 0.75rem);
      }

      > * {
        flex: 1;

        @include mixins.until(mixins.$tablet) {
          min-height: 250px;
        }

        .index-right-container-candidates-container {
          height: 80%;
          overflow-y: auto;
          scrollbar-width: thin;
        }
      }
    }
  }
}
