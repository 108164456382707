@use '../abstracts/mixins';
@use '../bases/helpers';

.layout {
  background-repeat: repeat-x !important;
  min-height: 100vh;
  overflow: hidden;

  > div {
    min-height: 100vh;

    div[class*='column'] {
      @include mixins.until(mixins.$tablet) {
        min-height: 100vh;
        // @include helpers.flex-col();
        img {
          max-width: 50%;
        }
      }

      &:nth-child(2) {
        @include mixins.from(mixins.$desktop) {
          max-width: calc(100vw / 3);
        }
      }

      img {
        max-width: 150px;
      }

      button {
        position: relative;

        [class*='ms-Spinner'] {
          bottom: 0;
          position: absolute;
          right: -20px;
          top: 3px;
        }
      }
    }
  }
}
