@use '../../../../abstracts/variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/mixins/Depth.Mixins';

.board-favorite-button {
  color: #a0aeb2;
  cursor: pointer;
  font-size: 14px;

  &:active {
    cursor: pointer !important;
  }

  &.is-active {
    color: #fce100;
  }
}
