.board-detail-panel-comment {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;

  .board-detail-panel-comment-inputs {
    flex: 1;

    > *:first-child {
      margin-bottom: -1px;
    }
  }

  .board-detail-panel-comment-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
