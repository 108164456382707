@use '../../../../abstracts/mixins';
@use '../../../../abstracts/variables';

.board-interview-panel {
  .board-interview-panel-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 100%;

    @include mixins.until(variables.$desktop) {
      flex-direction: column;

      .board-interview-panel-container-left {
        iframe.board-interview-panel-container-left-cv {
          min-height: 85vh;
        }
      }

      .board-interview-panel-container-right {
        padding: 1rem 0 !important;
      }
    }

    .board-interview-panel-container-left {
      flex: 1;

      iframe.board-interview-panel-container-left-cv {
        height: 100%;
        width: 100%;
      }
    }

    .board-interview-panel-container-right {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      padding: 0 1rem;

      .board-interview-panel-container-right-contents {
        p.board-interview-panel-container-right-contents-intro {
          display: block;
          margin-bottom: 10px;
          margin-top: -10px;
        }
      }

      .board-interview-panel-container-right-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 2.5px;
        justify-content: flex-start;
      }
    }
  }
}
