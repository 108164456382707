@use '../../../../abstracts/variables';
@use '../../../../abstracts/mixins';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~@fluentui/react/dist/sass/mixins/Depth.Mixins';

$light-blue: #00b0e3;
$light-grey: #d2d2d2;

.board-columns {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  padding-top: 10px;
  scrollbar-width: thin;
  transform: rotateX(180deg);
  white-space: nowrap;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(variables.$board-variant-grey-blue, 50%);
    border: transparent;
    border-radius: 5px;
  }

  .board-columns-column {
    background: variables.$white;
    border-radius: 1%;
    box-shadow: $ms-depth-shadow-8;
    display: inline-block;
    height: 800px;
    margin: 0 5px 10px;
    max-width: 250px;
    min-width: 250px;
    overflow: hidden;
    padding-bottom: 10px;
    transform: rotateX(180deg);

    header {
      padding: 10px 10px 0;

      .board-columns-column-header-top {
        display: flex;
        justify-content: space-between;

        .board-columns-column-header-top-number {
          color: $light-grey;
        }

        .board-columns-column-header-top-title {
          color: $light-grey;

          span {
            background: $light-blue;
            border-radius: 15%;
            color: variables.$white;
            margin-left: 5px;
            padding: 2px 5px;
          }
        }
      }

      .board-columns-column-header-bottom {
        display: flex;
        margin-top: 15px;

        i.board-columns-column-header-bottom-icon {
          color: variables.$board-variant-grey-blue;
          margin-right: 10px;
          vertical-align: text-top;
        }

        .board-columns-column-header-bottom-title {
          color: variables.$board-variant-grey-blue;
          margin-right: 10px;
          vertical-align: text-top;
        }
      }
    }

    .board-columns-column-content {
      display: flex;
      flex-direction: column;
      height: 88%;
      justify-content: flex-start;
      overflow: auto;
      padding: 0 10px;
    }
  }
}
